import React, { useState, useEffect, useRef } from 'react'
import axios from '../../axios-services'
import Paper from '@material-ui/core/Paper'
import Input from '../../components/UI/Input'
import Button from '@material-ui/core/Button'
import Alert from '@material-ui/lab/Alert'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import { makeStyles } from '@material-ui/core/styles'
import { generateQrCode } from '../../shared/utility'
import Tooltip from '@material-ui/core/Tooltip'

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
  },
  input: {
    margin: '0.6rem 0',
  },
  button: {
    marginTop: '50px',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  copyInput: {
    border: 'none',
    fontSize: '1.1rem',
    color: '#000',
    width: '300px',
    outline: '0',
    fontWeight: '500',
    fontFamily: 'Roboto',
    textAlign: 'left',
  },
  urlWrapper: {
    margin: '1rem 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  shortUrlWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  paragraph: {
    marginRight: '20px',
  },
  h3: {
    fontSize: '1.1rem',
    fontWeight: '500',
    fontFamily: 'Roboto',
    margin: '0',
    wordBreak: 'break-all'
  },
  title: {
    textAlign: 'left',
    marginLeft: '20px',
    fontSize: '1.25rem',
  },
}))

const LinkCreate = (props) => {
  const [linkForm, setLinkForm] = useState({
    url: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        placeholder: 'Enter long link (link on which user will land)',
      },
      value: '',
      validation: {
        required: true,
        minLength: 2,
      },
      valid: false,
      touched: false,
    },
    token: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        placeholder: 'Enter short link id (e.g. product EAN number)',
      },
      value: '',
      validation: {
        required: true,
        minLength: 2,
      },
      valid: false,
      touched: false,
    },
    description: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        placeholder: 'Enter description',
      },
      value: '',
      validation: {
        required: false,
        minLength: 2,
      },
      valid: false,
      touched: false,
    },
    domain: {
      elementType: 'select',
      elementConfig: {
        placeholder: 'Select Domain',
      },
      value: '',
      validation: {
        required: true,
        minLength: 2,
      },
      valid: false,
    },
  })

  const [successData, setSuccessData] = useState({
    short_url: 0,
  })

  const [fetchedDomains, setFetchedDomains] = useState([])

  const fetchDomains = () => {
    let auth = localStorage.getItem('netaAuth')
    auth = JSON.parse(auth)

    axios
      .get(`/domains`, { headers: { Authorization: 'Bearer ' + auth.token } })
      .then((response) => {
        if (!isMounted.current) {
          setFetchedDomains(response.data.data.domains)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const isMounted = useRef(false)

  useEffect(() => {
    fetchDomains()
    return () => {
      isMounted.current = true
    }
  }, [])

  const [isFormValid, setIsFormValid] = useState(false)
  const [showForm, setShowForm] = useState(true)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [showErrorMessageContent, setShowErrorMessageContent] = useState(null)

  const submitCodeHandler = (event) => {
    if (isFormValid === false) {
      return false
    }
    event.preventDefault()
    const formData = {}
    Object.keys(linkForm).forEach((key) => {
      formData[key] = linkForm[key].value
    })

    let auth = localStorage.getItem('netaAuth')
    auth = JSON.parse(auth)

    axios
      .post(`/short-links`, formData, {
        headers: { Authorization: 'Bearer ' + auth.token },
      })
      .then((response) => {
        setShowForm(false)
        setSuccessData(response.data.data)
      })
      .catch((error) => {
        if (error.response.data.errors) {
          setShowErrorMessageContent(error.response.data.errors)
        }
        setShowForm(true)
        setShowErrorMessage(true)
        setIsFormValid(false)
      })
  }

  const checkValidity = (value, rules) => {
    let isValid = true
    if (!rules) {
      return true
    }

    if (rules.required) {
      isValid = value.trim() !== '' && isValid
    }

    if (rules.minLength) {
      isValid = value.length >= rules.minLength && isValid
    }

    if (rules.maxLength) {
      isValid = value.length <= rules.maxLength && isValid
    }

    if (rules.isEmail) {
      const pattern =
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
      isValid = pattern.test(value) && isValid
    }

    if (rules.isCheckbox) {
      isValid = value && isValid
    }

    return isValid
  }

  const inputChangedHandler = (event, inputIdentifier) => {
    const updatedLinkForm = {
      ...linkForm,
    }
    const updatedFormElement = {
      ...updatedLinkForm[inputIdentifier],
    }
    updatedFormElement.value = event.target.value
    if (updatedFormElement.terms) {
      updatedFormElement.value = event.target.checked
    }
    updatedFormElement.valid = checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    )
    updatedFormElement.touched = true
    updatedLinkForm[inputIdentifier] = updatedFormElement
    let isFormValid = true
    for (let inputIdentifier in updatedLinkForm) {
      isFormValid = updatedLinkForm[inputIdentifier].valid && isFormValid
    }
    setLinkForm(updatedLinkForm)
    setIsFormValid(isFormValid)
  }

  const classes = useStyles()

  const formElementsArray = []
  for (let key in linkForm) {
    formElementsArray.push({
      id: key,
      config: linkForm[key],
    })
  }

  let form = (
    <div>
      <form onSubmit={submitCodeHandler} className={classes.form}>
        {formElementsArray.map((formElement) => (
          <Input
            customClass={classes.input}
            key={formElement.id}
            elementConfig={formElement.config.elementConfig}
            elementType={formElement.config.elementType}
            value={formElement.config.value}
            placeholder={formElement.config.elementConfig.placeholder}
            domains={fetchedDomains}
            changed={(event) => inputChangedHandler(event, formElement.id)}
            required={formElement.config.valid}
          />
        ))}
        {showErrorMessage && showErrorMessageContent
          ? Object.values(showErrorMessageContent).map((error, key) => {
            return (
              <Alert severity="error" key={key}>
                {error[0]}
              </Alert>
            )
          })
          : null}
        <Button
          type="submit"
          disabled={!isFormValid}
          variant="contained"
          color="primary"
          className={classes.button}
        >
          Submit
        </Button>
      </form>
    </div>
  )

  const textAreaRef = useRef(null)

  function copyToClipboard(e) {
    textAreaRef.current.select()
    document.execCommand('copy')
    e.target.focus()
  }

  return (
    <Paper className={classes.paper}>
      <h3 className={classes.title}>Create short link</h3>
      {showForm ? (
        <div>{form}</div>
      ) : (
        <div>
          <Alert severity="success">Short link is created successfully.</Alert>

          <div className={classes.urlWrapper}>
            <p className={classes.paragraph}>Full url:</p>
            <span className={classes.paragraph} style={{ wordBreak: 'break-all' }}>{successData.url}</span>
          </div>

          <div className={classes.urlWrapper}>
            <p className={classes.paragraph}>Short url:</p>
            <div className={classes.shortUrlWrapper}>
              <input
                type="text"
                className={classes.copyInput}
                ref={textAreaRef}
                value={successData.short_url}
                onChange={() => { }}
              />
              <Tooltip title="Copy link" placement="top">
                <FileCopyIcon
                  onClick={copyToClipboard}
                  style={{ cursor: 'pointer' }}
                />
              </Tooltip>

              <Tooltip title="Download QR code" placement="top">
                <img
                  onClick={() => {
                    generateQrCode(successData.short_url)
                  }}
                  style={{
                    width: '1.5em',
                    marginLeft: '10px',
                    cursor: 'pointer',
                  }}
                  src={require('../../assets/images/qr-code.svg')}
                  alt="qr-code-icon"
                />
              </Tooltip>
            </div>
          </div>
        </div>
      )}
      <div hidden>
        <canvas id="canvas" align="center" />
      </div>
    </Paper>
  )
}

export default LinkCreate
